<template>
  <div>
    <div v-show="readonly" :class="css">
      <slot name="readonly-value" v-bind="{ hasInnerValue, readonly, css, showValue, hideLabel, labelWithSuffix, formattedPhone, labelCss, valueCss }">
        <label v-if="!hideLabel" :class="labelCss">{{labelWithSuffix}}</label>
        <p v-if="showValue" :class="valueCss"><a :href="phoneUri">{{ formattedPhone }}</a></p>
      </slot>
    </div>
    <div v-show="!readonly">
      <ValidationProvider :vid="vid" :name="name" :rules="phoneRules" ref="valprov" :disabled="disableValidation">
        <b-form-group slot-scope="{ invalid, valid, validated, errors }" :disabled="readonly" :label="labelWithSuffix" :label-sr-only="hideLabel" :label-for="$attrs.id" :description="description" class="mb-0">
          <PhoneNumberInput
            v-model="innerValue"
            :id="domId"
            :name="name"
            @update="onUpdate($event, invalid)"
          />
          <b-form-invalid-feedback :state="valid"><span v-for="(error, idx) in errors" :key="idx">{{error}}</span></b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
    </div>
  </div>
</template>
<script>
import PhoneNumberInput from '@/shared/fields/VuePhoneNumberInput/index.vue'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import Vue from 'vue'
Vue.component('vue-phone-number-input', PhoneNumberInput)

import BaseField from '../inputs/base.vue'

export default {
  extends: BaseField,
  components: { PhoneNumberInput },
  props: {
    label: {
      type: String,
      default: 'Phone'
    },
    name: {
      type: String,
      default: 'phone'
    },
    triggerInnerValueChanges: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    domId() {
      if (this.$attrs.id) {
        return this.$attrs.id
      }
      return this.name
    },
    phoneRules() {
      if (this.required) {
        return 'required|min:7|phone'
      }
      return 'min:7|phone'
    }
  },
  data: () => ({
    phoneUri: null,
    formattedPhone: null
  }),
  methods: {
    onInput(e, valid) {
    },
    onUpdate(e, valid) {
      this.phoneUri = e.uri
      this.formattedPhone = e.formatInternational
      this.$emit('input', e.formattedNumber)
    }
  }
}
</script>
